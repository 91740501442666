<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Component Usage"
                >
                  <template v-slot:description>
                    If you have imported and registered the files correctly, the following two ways of using a component will work.
                  </template>
                </hb-page-header>
            </hb-header>
        </div>

       <hb-card class="mt-4 mb-6">
         
          <template v-slot:title>
            <span class="pr-3">Method 1</span> <span class="hb-text-light hb-font-body font-weight-regular">( Standard method, you will use this most of the time. )</span>
          </template>

          <div class="pa-2">
            <hb-switch class="ml-6" v-model="switch1" :label="switch1 ? 'On' : 'Off'">Stuff inside</hb-switch>
          </div>

          <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-switch class="ml-6" v-model="switch1" :label="switch1 ? 'On' : 'Off'">Stuff inside&lt;/hb-switch>
</pre>
          </hb-card>
        </hb-card>

        <hb-card class="mt-4 mb-6">

          <template v-slot:title>
            <span class="pr-3">Method 2</span> <span class="hb-text-light hb-font-body font-weight-regular">( Shorthand method, you can only use this method if there is no nested content placed within the opening and closing element tag. )</span>
          </template>

          <div class="pa-2">
            <HbSwitch class="ml-6" v-model="switch2" :label="switch2 ? 'On' : 'Off'" />
          </div>

          <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbSwitch class="ml-6" v-model="switch2" :label="switch2 ? 'On' : 'Off'" />
</pre>
          </hb-card>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemComponentUsage",
        data: function() {
            return {
              switch1: false,
              switch2: false
            };
        },
    }
</script>

<style scoped>

</style>
